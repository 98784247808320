<template>
  <div>
    <v-col cols="12" class="primary mt-4 mx-0 pa-6 white--text">
      <v-row>
        <div class="text-uppercase font-weight-bold">voting management</div>
      </v-row>
      <v-row class="">
        <div class="">
          Create the vote items to be voted for by the members in the meeting or
          session. Only the speaker can approve individual members to vote.
        </div>
      </v-row>
    </v-col>

    <div class="pa-2">
      <v-card-title>
        <v-menu
          v-model="fromMenu"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="from"
              label="From Date"
              prepend-icon="mdi-calendar"
              outlined
              readonly
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="from"
            @input="fromMenu = false"
          ></v-date-picker>
        </v-menu>

        <v-menu
          v-model="toMenu"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="to"
              label="To Date"
              outlined
              prepend-icon="mdi-calendar"
              readonly
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker v-model="to" @input="toMenu = false"></v-date-picker>
        </v-menu>
      </v-card-title>

      <v-card-title>
        <v-icon>mdi-text-search</v-icon>
        <div class="text-uppercase">Search Results</div>
        <v-spacer></v-spacer>
        <v-spacer></v-spacer>
        <v-text-field
          append-icon="mdi-magnify"
          outlined
          label="Search"
          dense
          v-model="search"
        ></v-text-field>
      </v-card-title>

      <v-card color="transparent">
        <v-row>
          <v-col>
            <v-dialog
              v-model="addDialog"
              width="auto"
              v-if="user.role.name !== 'Speaker'"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn color="primary" v-on="on" v-bind="attrs"
                  ><v-icon left>mdi-plus</v-icon> create vote item</v-btn
                >
              </template>
              <v-card width="500">
                <v-card-title>
                  <p class="mx-auto text-uppercase">create a vote item</p>
                </v-card-title>
                <v-divider></v-divider>
                <v-card-text>
                  <v-form v-model="addForm" ref="addForm">
                    <!-- date -->
                    <v-menu
                      v-model="addDateMenu"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="addDate"
                          label="From Date"
                          prepend-icon="mdi-calendar"
                          outlined
                          dense
                          readonly
                          :rules="[rules.required]"
                          v-bind="attrs"
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="addDate"
                        @input="addDateMenu = false"
                      ></v-date-picker>
                    </v-menu>
                    <!-- subject -->
                    <v-text-field
                      label="Voting Subject"
                      v-model="addSubject"
                      outlined
                      dense
                      :rules="[rules.required]"
                      prepend-icon="mdi-text"
                    />
                    <v-btn
                      color="primary"
                      block
                      :loading="addLoader"
                      :disabled="!addForm"
                      @click="createVoteItem()"
                      >submit</v-btn
                    >
                  </v-form>
                </v-card-text>
              </v-card>
            </v-dialog>

            <v-data-table
              :headers="headers"
              :items="items"
              :search="search"
              class="text-capitalize elevation-10"
              no-data-text="No results for the current date"
            >
              <template v-slot:[`item.voting`]="{ item }">
                <v-btn
                  rounded
                  small
                  v-if="item.voting_record.voting_status == 0"
                  color="primary"
                  @click="(votingDialog = true), (toVote = item.voting_record)"
                  >open voting</v-btn
                >
                <v-btn
                  rounded
                  small
                  v-else
                  color="primary"
                  @click="(votingDialog = true), (toVote = item.voting_record)"
                  >close voting</v-btn
                >
              </template>
              <template v-slot:[`item.meeting_date`]="{ item }">
                {{
                  new Date(item.voting_record.meeting_date)
                    | moment("Do MMM YYY")
                }}
              </template>
              <template v-slot:[`item.meeting_subject`]="{ item }">
                {{ item.voting_record.meeting_subject }}
              </template>
              <template v-slot:[`item.actions`]="{ item }">
                <v-btn
                  icon
                  color="primary"
                  @click="openEdit(item)"
                  :disabled="user.role.name == 'Speaker'"
                >
                  <v-icon>mdi-pencil</v-icon>
                </v-btn>
                <v-btn
                  icon
                  color="primary"
                  @click="(deleteDialog = true), (itemToDeleteId = item.id)"
                  :disabled="user.role.name == 'Speaker'"
                >
                  <v-icon>mdi-delete</v-icon>
                </v-btn>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-card>
    </div>

    <!-- edit dialog -->
    <v-dialog v-model="editDialog" width="auto">
      <v-card width="500" class="mx-auto">
        <v-card-title>
          <p class="mx-auto text-uppercase">edit vote item</p>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-form v-model="editForm" ref="editForm">
            <!-- date -->
            <v-menu
              v-model="editDateMenu"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="editDate"
                  label="From Date"
                  prepend-icon="mdi-calendar"
                  outlined
                  dense
                  readonly
                  :rules="[rules.required]"
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="editDate"
                @input="editDateMenu = false"
              ></v-date-picker>
            </v-menu>
            <!-- subject -->
            <v-text-field
              label="Voting Subject"
              v-model="editSubject"
              outlined
              dense
              :rules="[rules.required]"
              prepend-icon="mdi-text"
            />
            <v-btn
              color="primary"
              block
              :loading="editLoader"
              :disabled="!editForm"
              @click="editVoteItem()"
              >submit</v-btn
            >
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- delete dialog -->
    <v-dialog v-model="deleteDialog" width="auto">
      <v-card width="500" class="mx-auto">
        <v-card-title>
          <p class="mx-auto text-uppercase">delete item</p>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <p>Are you sure you want to delete this item?</p>
        </v-card-text>
        <v-card-actions>
          <v-btn color="primary" @click="deleteDialog = false">cancel</v-btn>
          <v-spacer></v-spacer>
          <v-btn color="error" :loading="deleteLoader" @click="deleteVoteItem"
            >delete</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- open voting dialog -->

    <v-dialog v-model="votingDialog" width="auto">
      <v-card>
        <v-card-title>
          <p class="mx-auto text-uppercase">open voting for this subject</p>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="black--text">
          Are you sure you want to open this topic ({{
            toVote !== null ? toVote.meeting_subject : ""
          }}) for voting?
        </v-card-text>
        <v-card-actions v-if="toVote != null">
          <v-btn color="primary" @click="votingDialog = false">cancel</v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            :loading="openVotingLoader"
            v-if="toVote.voting_status == 0"
            @click="openVoting()"
            >open voting</v-btn
          >
          <v-btn
            color="primary"
            :loading="openVotingLoader"
            v-else
            @click="closeVoting()"
            >close voting</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar
      v-model="snackBar"
      :color="snackBarData.success ? 'success' : 'error'"
    >
      <div class="text-center text-capitalize">
        {{ snackBarData.message }}
      </div>
    </v-snackbar>
  </div>
</template>

<script>
import { mapState } from "pinia";
import { userStore } from "../stores/user";
import Api from "@/services/Api";

export default {
  data: () => ({
    to: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substr(0, 10),
    from: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substr(0, 10),
    toMenu: false,
    fromMenu: false,
    search: "",
    headers: [
      {
        text: "meeting date",
        value: "meeting_date",
        class: "text-uppercase black--text",
      },
      {
        text: "subject",
        value: "meeting_subject",
        class: "text-uppercase black--text",
      },
      {
        text: "voting",
        value: "voting",
        class: "text-uppercase black--text",
      },
      {
        text: "actions",
        value: "actions",
        class: "text-uppercase black--text",
      },
    ],
    items: [],
    addDialog: false,
    addForm: false,
    addLoader: false,
    addDate: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substr(0, 10),
    addDateMenu: false,
    addSubject: "",
    editDialog: false,
    itemToEdit: null,
    deleteDialog: false,
    editLoader: false,
    editForm: false,
    editDate: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substr(0, 10),
    editDateMenu: false,
    editSubject: "",
    deleteLoader: false,
    itemToDeleteId: false,
    rules: {
      required: (v) => !!v || "Required",
    },
    votingDialog: false,
    openVotingLoader: false,
    toVote: null,
    snackBar: false,
    snackBarData: {
      success: false,
      message: "",
    },
  }),
  computed: {
    ...mapState(userStore, ["user"]),
  },
  methods: {
    createVoteItem() {
      this.addLoader = true;
      Api.createVoteItem({
        meeting_date: this.addDate,
        meeting_subject: this.addSubject,
      })
        .then(() => {
          this.showSnackBar(true, "Voting item created succesfully");
          this.$refs.addForm.reset();
          this.getVoteItems();
        })
        .catch((err) => {
          this.showSnackBar(false, err.response.data.message);
        })
        .finally(() => {
          this.addDialog = false;
          this.addLoader = false;
        });
    },
    getVoteItems() {
      Api.getVoteItems().then((res) => {
        this.items = res.data.data;
      });
    },
    openEdit(item) {
      this.itemToEdit = item;
      this.editDate = item.meeting_date;
      this.editSubject = item.meeting_subject;
      this.editDialog = true;
    },
    editVoteItem() {
      this.editLoader = true;
      Api.editVoteItem(this.itemToEdit.id, {
        meeting_date: this.editDate,
        meeting_subject: this.editSubject,
        voting_status: 0,
      })
        .then(() => {
          this.showSnackBar(true, "Succesfully edited the vote item");
          this.$refs.editForm.reset();
          this.getVoteItems();
        })
        .catch((err) => {
          this.showSnackBar(false, err.response.data.message);
        })
        .finally(() => {
          this.editDialog = false;
          this.editLoader = false;
        });
    },
    deleteVoteItem() {
      this.deleteLoader = true;

      Api.deleteVoteItem(this.itemToDeleteId)
        .then(() => {
          this.showSnackBar(true, "Vote item deleted succesfully");
          this.getVoteItems();
        })
        .catch((err) => {
          this.showSnackBar(false, err.response.data.message);
        })
        .finally(() => {
          this.deleteLoader = false;
          this.deleteDialog = false;
        });
    },
    openVoting() {
      this.openVotingLoader = true;
      Api.editVoteItem(this.toVote.id, {
        meeting_date: this.toVote.meeting_date,
        meeting_subject: this.toVote.meeting_subject,
        voting_status: 1,
      })
        .then(() => {
          this.showSnackBar(true, "Succesfully opened voting");
          this.getVoteItems();
        })
        .catch((err) => {
          this.showSnackBar(false, err.response.data.message);
        })
        .finally(() => {
          this.votingDialog = false;
          this.openVotingLoader = false;
        });
    },
    closeVoting() {
      this.openVotingLoader = true;
      Api.editVoteItem(this.toVote.id, {
        meeting_date: this.toVote.meeting_date,
        meeting_subject: this.toVote.meeting_subject,
        voting_status: 0,
      })
        .then(() => {
          this.showSnackBar(true, "Succesfully closed voting");
          this.getVoteItems();
        })
        .catch((err) => {
          this.showSnackBar(false, err.response.data.message);
        })
        .finally(() => {
          this.votingDialog = false;
          this.openVotingLoader = false;
        });
    },
    showSnackBar(success, message) {
      this.snackBar = true;
      this.snackBarData.success = success;
      this.snackBarData.message = message;
    },
  },
  created() {
    this.getVoteItems();
  },
};
</script>

<style></style>
